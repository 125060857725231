import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import {
  MainContainer,
  PageContainer,
  PageHeaders,
  PageHeading,
  PostArticle,
  PostContent,
  SectionSpacer,
} from "../../elements"
import { Layout } from "../../Layout"

interface PostProps {
  data: any
}

const Post: React.FC<PostProps> = ({ data }) => {
  const site = data.site
  const siteLogoSrc = data.imageSharp.fixed.src
  const postData = data.PostData

  const image = postData.frontmatter.featureImage
    ? postData.frontmatter.featureImage.childImageSharp.resize
    : null

  const schema = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    headline: postData.frontmatter.title,
    image: `${site.siteMetadata.siteUrl}${image ? image.src : null} `,
    description: postData.frontmatter.description,
    mainEntityOfPage: {
      "@type": "WebPage",
      url: `${site.siteMetadata.siteUrl}${postData.slug}`,
    },
    author: {
      "@type": "Person",
      name: postData.frontmatter.author,
    },
    publisher: {
      "@type": "Organization",
      name: site.siteMetadata.title,
      logo: {
        "@type": "ImageObject",
        url: `${site.siteMetadata.siteUrl}${siteLogoSrc}`,
      },
    },
    datePublished: postData.frontmatter.date,
  }

  return (
    <Layout
      title={postData.frontmatter.title}
      description={postData.frontmatter.description}
      pathname={postData.slug}
      image={image}
      schemaMarkup={schema}
    >
      <PageHeaders>
        <MainContainer>
          <PageHeading>
            <h2>{postData.frontmatter.title}</h2>
          </PageHeading>
        </MainContainer>
      </PageHeaders>

      <PageContainer>
        <MainContainer>
          <PostArticle>
            <PostContent>
              <MDXRenderer>{postData.body}</MDXRenderer>
            </PostContent>
          </PostArticle>
        </MainContainer>
      </PageContainer>

      <SectionSpacer marginTop="2em" marginBottom="2em" />
    </Layout>
  )
}
export default Post

export const pageQuery = graphql`
  query ($id: String!) {
    site {
      siteMetadata {
        title
        description
        keywords
        siteUrl
      }
    }
    imageSharp(fixed: { originalName: { eq: "best_for_your_feet_logo.png" } }) {
      fixed {
        src
      }
    }
    PostData: mdx(id: { eq: $id }, frontmatter: { published: { eq: true } }) {
      body
      slug
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        tags
        title
        description
        author
        featureImage {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
      timeToRead
    }
  }
`
